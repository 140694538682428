import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AdminNotesService} from '../_services/admin-notes.service';
import {UserService} from '../../../_upsell/shared/services/user.service';
import {Note} from '../../../modules/tickets/_models/notes.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-admin-notes',
    templateUrl: './admin-notes.component.html',
    styleUrls: ['./admin-notes.component.scss']
})
export class AdminNotesComponent implements OnInit, OnDestroy {


    @Input() moduleName: string;

    urlId: string = undefined;

    // Wait to load data
    lastAdminNoteLoaded: boolean;

    // User
    currentUserId: any;
    userRolls = [];

    // Notes
    staffNotes: Note[];
    lastStaffNote: Note = null;

    // Notes Form
    noteStaffInput = new FormControl('', Validators.required);

    // Feedback Messages
    formSent: boolean;
    formResponse: boolean;
    errorMsg: string;
    successMsg: string;


    // Unsubscribe
    unsubscribe = [];

    constructor(private adminNotesService: AdminNotesService,
                private route: ActivatedRoute,
                private userService: UserService,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        this.urlId = this.route.snapshot.params.id;
        this.getUser();
        this.getLastAdminNote();
        this.getAllStaffNotes(this.moduleName, this.urlId);
    }


    // Get User Data
    getUser() {
        const getCurrentUser = this.userService.getCurrentUser().subscribe((user: any) => {
            this.userRolls = user.roles;
            this.currentUserId = user.id;
        });

        // Add request to unsubscribe list
        this.unsubscribe.push(getCurrentUser);
    }


    // Create Admin Note
    createAdminNote() {

        this.noteStaffInput.markAllAsTouched();

        // Check if the input is empty
        if (this.noteStaffInput.value.trim().length === 0 && this.noteStaffInput.invalid) {
            return;
        }

        const staffNoteData = {
            note: this.noteStaffInput.value,
            date: new Date(),
            staffNote: true
        };


        // Add Staff Note
        const addStaffNote = this.adminNotesService.addModuleNote(this.moduleName, this.urlId, staffNoteData).subscribe(res => {
            // Make the input empty
            this.noteStaffInput.patchValue('');
            this.noteStaffInput.markAsUntouched();
            // Get all staff notes
            this.getAllStaffNotes(this.moduleName, this.urlId);
        });

        // Add request to unsubscribe list
        this.unsubscribe.push(addStaffNote);
    }


    getAllStaffNotes(moduleName, ticketId) {
        const getAllStaffNotes = this.adminNotesService.getAllStaffModuleNotes(moduleName, ticketId).subscribe((data: any) => {
            this.staffNotes = data;
        });

        // Add request to unsubscribe list
        this.unsubscribe.push(getAllStaffNotes);
    }


    getLastAdminNote() {
        const getLastAdminNote = this.adminNotesService.getLastModuleNote(this.moduleName, this.urlId).subscribe((data: any) => {

            if (data.reason === 'empty') {
                this.lastStaffNote = null;
            } else {
                this.lastStaffNote = data;
            }

            this.lastAdminNoteLoaded = true;
        }, error => {
            console.log(error);
            this.lastAdminNoteLoaded = true;
        });

        // Add request to unsubscribe list
        this.unsubscribe.push(getLastAdminNote);
    }

    onClickGetLastAdminNote() {
        const onClickGetLastAdminNote = this.adminNotesService.getLastModuleNote(this.moduleName, this.urlId).subscribe((data: any) => {
            if (data.reason === 'empty') {
                this.lastStaffNote = null;
            } else {
                this.lastStaffNote = data;
            }
        });

        // Add request to unsubscribe list
        this.unsubscribe.push(onClickGetLastAdminNote);
    }


    // Modals
    largeModal(modal) {
        this.modalService.open(modal, {
            size: 'lg',
            centered: true
        });
    }


    ngOnDestroy() {
        this.unsubscribe.forEach(el => el.unsubscribe());
    }
}
