<!--Admin Notes-->
<div id="admin-note"
     *ngIf="lastAdminNoteLoaded && (userRolls.includes('ROLE_super_admin') || userRolls.includes('ROLE_admin')) || userRolls.includes('ROLE_TicketsFull')">
    <div class="row align-items-md-center p-3">

        <!--Section Title-->
        <div class="col-xl-3 col-lg-12 col-md-12 col-12 mb-xl-0 mb-9">
            <span class="section-title">
                <i class="fa fa-star mr-2"></i>
                Administration Notes
            </span>
        </div>

        <!--Last Note-->
        <div class="col-xl-5 col-lg-12 col-md-12 col-12 mb-xl-0 mb-9" *ngIf="lastStaffNote !== null">
            <div class="last-note" (click)="largeModal(ViewAdminNotes)">
                <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                        <span class="user-name">{{lastStaffNote?.firstName}} {{lastStaffNote?.lastName}}</span>
                    </li>
                    <li class="list-inline-item">
                        <span class="note-timestamp custom-font">{{lastStaffNote?.date | date:'dd/MM/YYYY'}} {{lastStaffNote?.date | date:'h:mm a'}}</span>
                    </li>
                </ul>
                <p class="note-message custom-font m-0">{{lastStaffNote?.note.length > 50 ? (lastStaffNote?.note | slice: 0: 50) + '...' : lastStaffNote?.note}}</p>
            </div>
        </div>

        <!--Note List is Empty-->
        <div class="col-xl-5 col-lg-12 col-md-12 col-12 mb-xl-0 mb-9" *ngIf="lastStaffNote === null">
            <div class="last-note" (click)="largeModal(ViewAdminNotes)">
                <p class="m-0 no-notes">Be the first to leave a note...</p>
            </div>
        </div>

        <!--Read More-->
        <div class="col-xl-3 col-lg-6 col-md-6 col-6 mb-xl-0 mb-9">
            <div class="read-more" (click)="largeModal(ViewAdminNotes)">
                <span>
                    <i class="fa fa-comment-dots mr-2"></i>
                    Reply...
                </span>
            </div>
        </div>

        <!--Refresh Button-->
        <div class="col-xl-1 col-lg-6 col-md-6 col-6 mb-xl-0 mb-9">
            <div class="refresh-note">
                <div class="bg" (click)="onClickGetLastAdminNote()">
                    <i class="fa fa-sync"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<!--View Admin Notes Modal-->
<ng-template #ViewAdminNotes let-c="close">
    <!--Modal Upper Section-->
    <div class="admin-note-header">
        <h3 class="modal-title text-white">Administration Notes</h3>
        <i class="fa fa-times text-white cursor-pointer" (click)="c()"></i>
    </div>
    <!--Modal Middle Section-->
    <div class="modal-body admin-note-body blue-scroll-bar" *ngIf="staffNotes.length > 0">
        <div class="d-flex flex-column mb-10" *ngFor="let note of staffNotes">
            <div *ngIf="note.subUserId === currentUserId">
                <ul class="list-unstyled d-flex align-items-center flex-row-reverse text-right">
                    <li class="list-inline-item ml-4">
                        <img src="{{note.subUserProfileImagePath}}" alt="User Profile" class="user-img img-fluid">
                    </li>
                    <li class="list-inline-item">
                        <span class="d-block user-name">{{note.firstName}} {{note.lastName}}</span>
                        <span class="d-block user-time custom-font">{{note.date | date:'dd/MM/YYYY'}} {{note.date | date:'h:mm a'}}</span>
                    </li>
                </ul>
                <div class="d-flex flex-row-reverse">
                    <span class="note-msg custom-font">{{note.note}}</span>
                </div>
            </div>

            <div *ngIf="note.subUserId !== currentUserId">
                <ul class="list-unstyled d-flex align-items-center">
                    <li class="list-inline-item mr-4">
                        <img src="{{note.subUserProfileImagePath}}" alt="User Profile" class="user-img img-fluid">
                    </li>
                    <li class="list-inline-item">
                        <span class="d-block user-name">{{note.firstName}} {{note.lastName}}</span>
                        <span class="d-block user-time custom-font">{{note.date | date:'dd/MM/YYYY'}} {{note.date | date:'h:mm a'}}</span>
                    </li>
                </ul>

                <div class="d-flex">
                    <span class="note-msg custom-font">{{note.note}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body admin-note-body d-flex flex-column justify-content-center align-items-center"
         *ngIf="staffNotes.length === 0">
        <i class="fa fa-comment-slash no-note-icon mb-2"></i>
        <span class="custom-font no-note">Notes are empty...</span>
    </div>
    <!--Modal Bottom Section-->
    <div class="admin-note-footer">
        <div class="d-flex justify-content-lg-start w-100">
            <!--Text Input-->
            <div class="input-area col-11">
                 <textarea class="form-control resize-none admin-note-textarea" rows="1" placeholder="Leave a message"
                           [formControl]="noteStaffInput"></textarea>
                <div class="error-warning" translate="VALIDATION.REQUIRED_FIELD"
                     *ngIf="noteStaffInput.invalid && noteStaffInput.touched"></div>
            </div>

            <!--Send Button-->
            <div class="submit-area col-1">
                <button class="btn-default btn-green flex-row-reverse" (click)="createAdminNote()">
                    <span class="fa fa-paper-plane"></span>
                </button>
            </div>
        </div>
    </div>
</ng-template>
