import {Injectable} from '@angular/core';
import {UserService} from '../../_upsell/shared/services/user.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CurrentUserDataService {

    copyCurrentUserData: object = undefined;
    currentUserData: object = undefined;

    constructor(private userService: UserService, private http: HttpClient) {
    }

    getCurrentUserData() {

        // Check if the object have any data
        if (!this.currentUserData) {
            return this.getUserRoles();
        }

            // Check the difference between the two object
        // if there are difference recall the api request and refresh the data
        else if (JSON.stringify(this.copyCurrentUserData) !== JSON.stringify(this.currentUserData)) {
            return this.getUserRoles();
        }

        // Return the populated object
        else {
            return new Observable(data => {
                data.next(this.currentUserData);
            });
        }
    }


    // Store User Roles
    getUserRoles() {
        return this.currentUserData = this.http.get('/api/getCurrentUser').pipe(map((data: any) => {
            this.copyCurrentUserData = data;
            this.currentUserData = data;
            console.log(data.roles);
            return data;
        }));
    }
}



