import {Component, OnDestroy, OnInit,} from '@angular/core';
import {TranslationService} from './modules/i18n/translation.service';
// language list
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as roLang} from './modules/i18n/vocabs/ro';
import {SplashScreenService} from './_metronic/partials/layout/splash-screen/splash-screen.service';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TableExtendedService} from './_metronic/shared/crud-table';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {


    private unsubscribe: Subscription[] = [];

    constructor(
        private translationService: TranslationService,
        private splashScreenService: SplashScreenService,
        private router: Router,
        private tableService: TableExtendedService,
        private modalService: NgbModal
    ) {

        // register translations
        this.translationService.loadTranslations(
            enLang,
            roLang
        );
    }

    ngOnInit() {
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // clear filtration pagination's and others
                this.tableService.setDefaults();
                // hide splash screen
                this.splashScreenService.hide();

                // close all modals on route change
                this.modalService.dismissAll();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
