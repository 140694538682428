// Romana
export const locale = {
    lang: 'ro',
    data: {
        OFFERS: {
            ADD: 'Adauga Oferta',
            EDIT: 'Edit Offer',
            NAME: 'Nume',
            DOC_NUMBER: 'Numar document',
            TOTAL_PRICE: 'Pret total',
            OFFER_DATE: 'Data oferta',
            DUE_DATE: 'Data expirare',
            MANUAL_ESTIMATION: 'Estimare manuala',
            STATUS: 'Status',
            SAVED_AS_TEMPLATE: 'Salveaza ca model',
            TEMPLATE_NAME: 'Numele modelului',
            DATE_ADDED_IN_APPLICATION: 'Data la care a fost adaugata aplicatia',
            IS_PUBLIC: 'Este public',
            CONTACT_ID: 'Id contact',
            CONTRACTS: 'Contracte',
            PRODUCT_LIST: 'Lista produse',
            PERSONAL_NOTES: {
                TITLE: 'Notite',
                AUTHOR: 'Autor',
                NOTE: 'Nota',
                DATE: 'Data',
            },
            SUBUSER_NAME: 'Utilizator',
            LIST: 'Listă oferte'
        },
        PROJECTS: {
            LIST: 'Listă proiecte',
            ADD: 'Add project',
            STATUS_DRAFT: 'Ciorna',
            STATUS_IN_PROGRESS: 'In desfasurare',
            STATUS_PENDING: 'In asteptare',
            STATUS_FINISHED: 'Finalizat',
            STATUS_CANCELLED: 'Anulat',
            BUDGET: 'Buget',
            DESCRIPTION: 'Descriere',
            NAME: 'Nume',
            START_DATE: 'Data de inceput',
            DEADLINE: 'Termen limita',
            STATUS: 'Status',
            DOC_NUMBER: 'Numar Document',
            CONTRACTS: 'Contracte',
            FISCAL_BILL: 'Factura fiscal',
            CLIENT_NAME: 'Nume client',
            CLIENT_EMAIL: 'Email client',
            CLIENT_PHONE: 'Nr. telefon client',
            CLIENT_ID: 'Id client',
            COMPANY_RESPONSIBLE: 'Responsabil companie',
            PRODUCTS_LIST: 'Lista produse/servicii',
            TEAM_MEMBER: 'Membru echipa',
            STEP_NAME: 'Nume Etapa',
            ORDER: 'Numar de ordine',
            DURATION: 'Durata',
            RESPONSIBLE_ID: 'Responsabil',
            NECESSARY_RESOURCE: 'Resuerse necesare',
            OBSERVATION: 'Observatii',
        },
        CONTACTS: {
            CONTACTS_LIST: 'Listă contacte',
            ADD_NEW: 'Adaugă contact',
            FULL_NAME: 'Nume',
            FIRST_NAME: 'Prenume',
            LAST_NAME: 'Nume',
            DATE_INITIAL_CONTACT: 'Data primului contact',
            PHONE: 'Numar telefon',
            EMAIL: 'Email',
            CONTACT_TITLE: 'Contact title',
            USER_LINKED_IN: 'LinkedIn Link',
            USER_OBSERVATION: 'Observatii',
            REFERRAL_SOURCE: 'Cod de afiliere',
            STATUS: 'Status',
            CONTACT_OBSERVATION: 'Observatii contact',
            RATING: 'Rating',
            PROJECT_DESCRIPTION: 'Descrierea proiectului',
            BUDGET: 'Buget',
            ATTACHMENTS: 'Atasamente',
            COMPANY_NAME: 'Nume companie',
            VAT_NUMBER: 'Numar VAT',
            INDUSTRY: 'Industrie',
            CATEGORIES: 'Categorii',
            ADDRESS_NAME: 'Adresa',
            ADDRESS_STREET_NAME: 'Numele strazii',
            ADDRESS_CITY: 'Oras',
            ADDRESS_STATE: 'Judet',
            ADDRESS_COUNTRY: 'Tara',
            BACKGROUND_INFO: 'Istoric companie',
            WEBSITE: 'Website',
            ZIP_CODE: 'Cod postal',
            COMPANY_FACEBOOK_PROFILE: 'Profil Facebook',
            COMPANY_LINKEDIN_PROFILE: 'Profil LinkedIn',
            COMPANY_REVENUE: 'Bugetul companiei',
            COMPANY_FOUNDATION_DATE: 'Data infiintare companie',
            COMPANY_EMAIL_ADDRESS: 'Adresa email',
            ACTIONS: 'Actiuni',
            CHANGE_STATUS: 'Schimba status',
            STATUS_ACTIVE: 'activ',
            STATUS_INACTIVE: 'inactiv',
            CREATE_SUCCESS: 'Contactul a fost creat cu succes!',
            CREATE_ERROR: 'A aparut o eroare!',
            NOTE: 'Notita',
            NOTE_SUCCESS: 'Notita a fost adaugata cu succes',
            NOTE_DELETE_SUCCESS: 'Notita a fost stearsa',
            NOTE_EDIT_SUCCESS: 'Notita a fost modificata',

        },
        TRANSLATOR: {
            SELECT: 'Selecteaza o limba',
        },
        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Ai uitat parola?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Parola ta a fost resetata cu succes.'
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.'
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username'
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
            }
        },
        ECOMMERCE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Selected records count: ',
                ALL: 'All',
                SUSPENDED: 'Suspended',
                ACTIVE: 'Active',
                FILTER: 'Filter',
                BY_STATUS: 'by Status',
                BY_TYPE: 'by Type',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individual',
                SEARCH: 'Cauta',
                IN_ALL_FIELDS: 'in all fields'
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Customers',
                CUSTOMERS_LIST: 'Customers list',
                NEW_CUSTOMER: 'New Customer',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Customer Delete',
                    DESCRIPTION: 'Are you sure to permanently delete this customer?',
                    WAIT_DESCRIPTION: 'Customer is deleting...',
                    MESSAGE: 'Customer has been deleted'
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Customers Delete',
                    DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                    WAIT_DESCRIPTION: 'Customers are deleting...',
                    MESSAGE: 'Selected customers have been deleted'
                },
                UPDATE_STATUS: {
                    TITLE: 'Status has been updated for selected customers',
                    MESSAGE: 'Selected customers status have successfully been updated'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Customer has been updated',
                    ADD_MESSAGE: 'Customer has been created'
                }
            }
        },
        PRODUCTS: {
            LIST: 'Listă produse',
            NEW: 'Adăugare produs',
            EDIT: 'Editare produs',
            PROD_TITLE: 'Titlu',
            PROD_DESCRIPTION: 'Descriere',
            PROD_TAGS: 'Tag-uri',
            PROD_SUBCAT: 'Subcategorii',
            PROD_CAT: 'Categorie',
            PROD_PRICE: 'Preț',
            PROD_ORDER: 'Cantitate',
            PROD_ESTIM_TIME: 'Timp estimat',
            TYPE_SERVICE_PRICE: 'oră',
            PROD_SERVICE: 'serviciu',
            PROD_PRODUCT: 'produs',
            PROD_TYPE: 'Tip',
            PROD_STATUS: 'Status',
            SERVICE_TIME: 'ore',
            PRODUCT_QUANT: 'produse',
            CHOOSE_TYPE: 'Alege tipul'
        },
        GENERAL: {
            BACK: 'Înapoi',
            CANCEL: 'Anulare',
            SAVE: 'Salvare',
            RESET: 'Resetare',
            UPLOAD: 'Incarca',
            CLEAR: 'Goleste',
            IMPORT: 'Import',
            REQUIRED_FIELD: 'Acest câmp este obligatoriu!',
            SELECTED_RECORDS_COUNT: 'Numărul elementelor selectate: ',
            DELETE_ALL: 'Stergeti tot',
            UPDATE_STATUS: 'Actualizare status',
            SHOWING_ROWS_FROM: 'Afișare rânduri de la',
            SHOWING_ROWS_TO: 'la',
            SHOWING_ROWS_OF: 'din',
            STATUS_UPDATE_SELECTED_ITEMS: 'Actualizare status pentru elementele selectate',
            ACTIONS: 'Acțiuni',
            STATUS_ACTIVE: 'activ',
            STATUS_INACTIVE: 'arhivat',
            STATUS_DRAFT: 'ciorna',
            STATUS_PUBLISHED: 'publicat',
            CHANGE_STATUS: 'Schimbare status',
            CHOOSE_STATUS: 'Alege statusul',
            CHOOSE_OPTION: 'Alege o opțiune',
            STATUS_CHANGED: 'Statusul a fost schimbat cu success!',
            ERROR: 'Ceva nu a funcționat. Te rugăm să mai încerci.',
            YES: 'Da',
            NO: 'Nu',
            STATUS: 'Status',
            SAVE_AS_DRAFT: 'Salveaza ca ciorna',
            NO_FILES_SELECTED: 'Nu exista fisiere',
            NO_FILES_UPLOADED: 'Nu exista fisiere incarcate'
        },
        TABLES: {
            processing: 'Procesează...',
            lengthMenu: 'Afișează _MENU_ înregistrări pe pagină',
            zeroRecords: 'Nu am găsit nimic - ne pare rău',
            info: 'Afișate de la _START_ la _END_ din _TOTAL_ înregistrări',
            infoEmpty: 'Afișate de la 0 la 0 din 0 înregistrări',
            infoFiltered: '(filtrate dintr-un total de _MAX_ înregistrări)',
            search: 'Caută:',
            paginate: {
                first: 'Prima',
                previous: 'Precedenta',
                next: 'Următoarea',
                last: 'Ultima'
            },
            aria: {
                sortAscending: 'Sortează ascendent',
                sortDescending: 'Sortează descendent'
            },
            loadingRecords: 'Încarcă...',
        },
        MENU: {
            PRODUCTS: 'Produse',
            ADD_PRODUCT: 'Adaugă produse',
            LIST_PRODUCTS: 'Listă produse',
            CONTACTS: 'Contacte',
            ADD_CONTACT: 'Adaugă contact',
            LIST_CONTACTS: 'Listă contacte',
            OFFERS: 'Oferte',
            ADD_OFFER: 'Adaugă ofertă',
            LIST_OFFERS: 'Listă oferte',
            CONTRACTS: 'Contracte',
            ADD_CONTRACT: 'Adaugă contract',
            LIST_CONTRACTS: 'Listă contracte',
            PROJECTS: 'Proiecte',
            ADD_PROJECT: 'Adaugă proiect',
            LIST_PROJECTS: 'Listă proiecte',
            TASKS: 'Task-uri',
            ADD_TASK: 'Adaugă task',
            LIST_TASKS: 'Listă task-uri',
            DIRECTIVES: 'Directive',
            ADD_DIRECTIVE: 'Adaugă directivă',
            LIST_DIRECTIVES: 'Listă directive',
            PROCEDURES: 'Proceduri',
            ADD_PROCEDURE: 'Adaugă procedură',
            LIST_PROCEDURES: 'Listă proceduri',
            FEEDBACK: 'Feedback',
            ADD_FEEDBACK: 'Adaugă feedback',
            LIST_FEEDBACK: 'Listă feedback',
            USERS: 'Utilizatori',
            ADD_USER: 'Adaugă utilizator',
            LIST_USERS: 'Listă utilizatori',
            REPORTS: 'Rapoarte',
            ADD_REPORT: 'Adaugă raport',
            LIST_REPORTS: 'Listă rapoarte',
            MY_PROFILE: 'Profilul meu',
            EDIT_MY_PROFILE: 'Editare profil',
            MY_SCHEDULE: 'Pontajul meu',
            MY_HOLIDAY_REQUEST: 'Cereri concediu'
        },
        USER: {
            PERSONAL_INFO: 'Informații personale',
            UPDATE_PERS_INFO: 'Actualizați-vă informațiile personale',
            F_NAME: 'Prenume',
            L_NAME: 'Nume',
            ADDRESS: 'Adresă',
            CITY: 'Oraș',
            COUNTRY: 'Țară',
            PERSONAL_PHONE: 'Telefon personal',
            DEPARTMENT: 'Departament',
            ROLES: 'Roluri',
            TITLE: 'Titlu',
            HIRING_DATE: 'Data angajării',
            EMP_STATUS: 'Status angajat',
            EMP_TYPE: 'Tip contract',
            WORK_PHONE: 'Telefon de serviciu',
            SOURCE_OF_HIRE: 'Sursa angajării',
            REPORTING_TO: 'Raportează către',
            COMPANY_NAME: 'Numele companiei',
            TEAM_NAME: 'Numele echipei',
            BLOOD_GROUP: 'Grupa de sânge',
            PERSONAL_EMAIL: 'Email personal',
            BIRTH_DATE: 'Data nașterii',
            GENDER: 'Gen',
            NATIONALITY: 'Naționalitate',
            CIVIL_STATUS: 'Statut civil',
            DRIVER_LICENSE: 'Permis de conducere',
            HOBBY: 'Hobby-uri',
            BACKUP_CONTACT: 'Contact de siguranță',
            BACKUP_C_NAME: 'Nume complet',
            BACKUP_C_PHONE: 'Numar de telefon',
            BACKUP_C_RELATIONSHIP: 'Relație cu persoana de siguranță',
            BACKUP_C_EMAIL: 'Adresa de email',
            LINKEDIN_PROFILE: 'Profil de Linkedin',
            FACEBOOK_PROFILE: 'Profil de Facebook',
            OTHER_SOCIAL_ACCOUNT: 'Altă rețea de socializare',
            WORK_EXP_PREV_COMPANY: 'Companie',
            WORK_EXP_JOB_TITLE: 'Titlu job',
            WORK_EXP_START_DATE: 'Data de inceput',
            WORK_EXP_END_DATE: 'Data de sfarsit',
            WORK_EXP_JOB_DESC: 'Descriere job',
            EDUCATION_SCHOOL: 'Numele unității de învățământ',
            EDUCATION_DEGREE: 'Diplomă',
            EDUCATION_FIELD: 'Domeniu de studiu',
            EDUCATION_YEAR: 'Anul finalizării studiilor',
            DELETE_INFO: 'Șterge grupul de informații',
            ADD_EDUCATION: 'Adaugă informații despre educație',
            ADD_WORK: 'Adaugă experiență în muncă',
            BASIC_INFO: 'Informații de bază',
            JOB_INFO: 'Informații despre job',
            WORK_EXP: 'Experiență de muncă',
            EDUCATION: 'Educație',
            FULL_NAME: 'Nume utilizator',
            COMPANY: 'Companie',
            STATUS: 'Status',
            ACTIVE: 'Activ',
            INACTIVE: 'Inactiv',
            NEW: 'Adaugă utilizator',
            LIST: 'Listă utilizatori',
            LIST_ALL: 'Toți utilizatorii'
        },
        LISTS: {
            WORK: {
                DEPARTMENT_1: 'Departament 1',
                DEPARTMENT_2: 'Departament 2',
                DEPARTMENT_3: 'Departament 3'
            },
            ROLE: {
                ROLE_1: 'Rol 1',
                ROLE_2: 'Rol 2',
                ROLE_3: 'Rol 3'
            },
            TITLE: {
                TITLE_1: 'Titlu 1',
                TITLE_2: 'Titlu 2',
                TITLE_3: 'Titlu 3'
            },
            EMPLOYEE_STATUS: {
                STATUS_1: 'Status 1',
                STATUS_2: 'Status 2',
                STATUS_3: 'Status 3'
            },
            EMPLOYEE_TYPE: {
                TYPE_1: 'Tip 1',
                TYPE_2: 'Tip 2',
                TYPE_3: 'Tip 3'
            },
            HIRE_SOURCE: {
                SOURCE_1: 'Sursa 1',
                SOURCE_2: 'Sursa 2',
                SOURCE_3: 'Sursa 3'
            },
            GENDER: {
                MALE: 'Masculin',
                FEMALE: 'Feminin',
            }
        },
        PROCEDURES: {
            TITLE: 'Title',
            CATEGORIES: 'Categories',
            DESCRIPTION: 'Description',
            STATUS: 'Status',
            DATE: 'Data',
            ACTIONS: 'Actiuni',
            LIST: 'Lista proceduri',
            ADD_NEW: 'Adauga procedura'
        },
        DIRECTIVES: {
            TITLE: 'Title',
            CATEGORIES: 'Categories',
            DESCRIPTION: 'Description',
            STATUS: 'Status',
            DATE: 'Data',
            ACTIONS: 'Actiuni',
            LIST: 'Lista proceduri',
            ADD_NEW: 'Adauga directiva'
        },
        NOTES: {
            SEND_NOTE: 'Send',
            NOTES_LABEL: 'Notes'
        },
        TICKETS: {
            ADD_TICKET: 'Adauga Ticket',
            ARCHIVE_TICKET: 'Arhiveaza Ticket',
            CLOSE_TICKET: 'Inchide Ticket',
            CANCEL_TICKET: 'Anuleaza',
            SUBMIT_TICKET: 'Trimite',
            SAVE_TICKET: 'Salveaza',
            EDIT_TICKET: 'Editeaza',
            READ_TICKET: 'Citeste Ticket'
        }
    }
};
