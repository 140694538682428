import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AdminNotesService {


    constructor(private http: HttpClient) {
    }


    // Create a note for specific module
    addModuleNote(moduleName, id, note: object) {
        return this.http.post('/api/createNote?objectId=' + id + '&objectType=' + moduleName, note);
    }

    // Get all specific module notes
    getAllStaffModuleNotes(moduleName, id) {
        return this.http.get('/api/getStaffNotes?objectId=' + id + '&objectType=' + moduleName);
    }

    // Get Last Note
    getLastModuleNote(moduleName, id) {
        return this.http.get('/api/getLastStaffNote?objectId=' + id + '&objectType=' + moduleName);
    }


}
