<div class="alert-animate">
    <div class="custom-alert" [ngClass]="(success ? 'success' : 'error')">

        <!--Custom Success Message-->
        <span *ngIf="customSuccessMessage && success; else defaultSuccessMessage">
            <i class="fa fa-check-circle pr-2"></i>{{customSuccessMessage}}
        </span>

        <!--Custom Error Message-->
        <span *ngIf="customErrorMessage && !success; else defaultErrorMessage">
            <i class="fa fa-exclamation-circle pr-2"></i>
            {{customErrorMessage}}
        </span>

        <!--Default Redirect Message-->
        <span *ngIf="redirect && success"> You will be redirected in a few moments.</span>

        <!--Default Success Message-->
        <ng-template class="row" #defaultSuccessMessage>
            <i class="fa-solid fa-circle-check"></i>
            <span *ngIf="success">The form has been sent successfully!</span>
        </ng-template>

        <!--Default Error Message-->
        <ng-template class="row" #defaultErrorMessage>
            <i class="fa-solid fa-circle-exclamation"></i>
            <span *ngIf="!success">Something didn't work. Please try again.</span>
        </ng-template>

    </div>
</div>