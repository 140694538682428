import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-dropdown-item',
    templateUrl: './dropdown-list.component.html',
    styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownMenuItem implements OnInit {

    @Input() model: any;

    constructor() {
    }

    ngOnInit(): void {
    }
}
