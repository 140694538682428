import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-form-message',
    templateUrl: './form-message.component.html',
    styleUrls: ['./form-message.component.scss']
})
export class FormMessageComponent {

    @Input() success: boolean;
    @Input() redirect: boolean;
    @Input() customSuccessMessage: string;
    @Input() customErrorMessage: string;

}
