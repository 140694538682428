import {Component, Input, OnInit} from '@angular/core';
import {ItemModel} from './_model/list.model';

@Component({
    selector: 'app-dropdown-menu',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownMenuComponent implements OnInit {

    @Input() ItemModel: any;

    List: ItemModel[];

    constructor() {
    }

    ngOnInit(): void {
    }
}
