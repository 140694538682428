import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) {
    }

    getCurrentUser() {
        return this.http.get('/api/getCurrentUser');
    }

    getAllUsers() {
        return this.http.get('/api/getAllUsers');
    }

    getUserById(userId) {
        return this.http.get('/api/getUserById?userId=' + userId);
    }

    getTeamMembers(includeCurrentUser: boolean, withInactiveUsers: boolean) {
        return this.http.get('/api/getTeamMembers?includeCurrentUser=' + includeCurrentUser + '&withInactiveUsers=' + withInactiveUsers);
    }


    updateCurrentUser(user) {
        return this.http.put('/api/updateCurrentUser', user);
    }

    updateCurrentSubUser(user) {
        return this.http.put('/api/updateCurrentSubUser', user);
    }

    getUserSettings() {
        return this.http.get('/api/getUserSettings');
    }

    updateSubUser(userId, user) {
        return this.http.put('/api/updateSubUser?subUserId=' + userId, user);
    }

    // Titles
    getAllUserTitles() {
        return this.http.get('/api/getAllUserTitles');
    }


}
