import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormMessageComponent} from './form-message/form-message.component';
import {AddAttachmentsComponent} from './attachments/add-attachments.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InlineSVGModule} from 'ng-inline-svg';
import {AdminNotesComponent} from './notes/admin-notes/admin-notes.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgbCollapseModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {ExportCsvComponent} from './export-csv/export-csv.component';
import {AngularMyDatePickerModule} from 'angular-mydatepicker';
import {DropdownMenuComponent} from './dropdown/dropdown.component';
import {DropdownMenuItem} from './dropdown/dropdown-list/dropdown-list.component';
import {RoleChecker} from '../_upsell/shared/pipes/user-rolles-checker.pipe';

@NgModule({
    declarations: [
        FormMessageComponent,
        AddAttachmentsComponent,
        AdminNotesComponent,
        ExportCsvComponent,
        DropdownMenuComponent,
        DropdownMenuItem,
        RoleChecker
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InlineSVGModule,
        TranslateModule,
        NgbCollapseModule,
        AngularMyDatePickerModule,
        NgbDropdownModule
    ],
    exports: [
        FormMessageComponent,
        AddAttachmentsComponent,
        AdminNotesComponent,
        ExportCsvComponent,
        DropdownMenuComponent,
        RoleChecker,
    ]
})
export class SharedModule {
}
