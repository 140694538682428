<!--begin::Navigation-->
<div ngbDropdown
     placement="bottom-right"
     class="dropdown dropdown-inline"
     title="Quick actions">

    <a ngbDropdownToggle class="btn btn-clean btn-sm btn-icon">
        <i class="ki ki-bold-more-hor" [ngClass]="ItemModel === true ? 'text-white' : 'text-black-50'"></i>
    </a>

    <div ngbDropdownMenu
         class="dropdown-menu dropdown-menu-md dropdown-menu-right">
        <app-dropdown-item></app-dropdown-item>
    </div>

</div>
<!--end::Navigation-->
