// USA
export const locale = {
    lang: 'en',
    data: {
        TRANSLATOR: {
            SELECT: 'Select your language',
        },
        PROJECTS: {
            ALL_MEMBERS: 'All members',
            ADD_NEW_MEMBER: 'Add new member',
            REMOVE_STAGE: 'Remove Stage',
            LIST: 'Projects list',
            ADD: 'Add project',
            STAGE: 'Stage',
            CREATE_STAGE: 'Create stage',
            TASK: 'Task',
            MY_TASK: 'My Task',
            NEW_TASK: 'New task',
            EDIT_TASK: 'Edit task',
            DELETE_TASK: 'Delete Task',
            MEMBER: 'Member',
            STATUS_DRAFT: 'Draft',
            STATUS_IN_PROGRESS: 'In progress',
            STATUS_PENDING: 'Pending',
            STATUS_FINISHED: 'Finished',
            STATUS_CANCELLED: 'Cancelled',
            BUDGET: 'Budget',
            DESCRIPTION: 'Description',
            NAME: 'Name',
            START_DATE: 'Starting date',
            DEADLINE: 'Deadline',
            STATUS: 'Status',
            DOC_NUMBER: 'Doc number',
            CONTRACTS: 'Contracts',
            FISCAL_BILL: 'Fiscal bill',
            CLIENT_NAME: 'Client name',
            CLIENT_EMAIL: 'Client Email',
            CLIENT_PHONE: 'Client phone number',
            CLIENT_ID: 'Client id',
            COMPANY_RESPONSIBLE: 'Company responsible',
            PRODUCTS_LIST: 'Products/Services List',
            TEAM_MEMBER: 'Team Member',
            STEP_NAME: 'Name',
            ORDER: 'Order number',
            DURATION: 'Duration',
            RESPONSIBLE_ID: 'Responsible person',
            NECESSARY_RESOURCE: 'Necessary resource',
            OBSERVATION: 'Observation',
        },
        OFFERS: {
            ADD: 'Add Offer',
            NAME: 'Name',
            DOC_NUMBER: 'Doc. number',
            TOTAL_PRICE: 'Total price',
            OFFER_DATE: 'Offer date',
            DUE_DATE: 'Due date',
            MANUAL_ESTIMATION: 'Manual estimation',
            STATUS: 'Status',
            SAVED_AS_TEMPLATE: 'Saved as template',
            TEMPLATE_NAME: 'Template name',
            DATE_ADDED_IN_APPLICATION: 'Date added in aplication',
            IS_PUBLIC: 'Is public',
            CONTACT_ID: 'Id contact',
            CONTRACTS: 'Contracts',
            PRODUCT_LIST: 'Product list',
            PERSONAL_NOTES: {
                TITLE: 'Notes',
                AUTHOR: 'Author',
                NOTE: 'Note',
                DATE: 'Date',
            },
            SUBUSER_NAME: 'User'
        },
        CONTACTS: {
            CONTACT: 'Contact',
            COMPANY: 'Company',
            ADDRESS: 'Address',
            DETAILS: 'Details',
            BLACKLISTED: 'Blacklisted',
            OWNER: 'Owner',
            ADDITIONAL_DETAILS: 'Additional Details',
            CONTACTS_LIST: 'Contacts list',
            FULL_NAME: 'Name',
            ADD_NEW: 'Add Contact',
            FIRST_NAME: 'First name',
            LAST_NAME: 'Last name',
            DATE_INITIAL_CONTACT: 'Date of initial contact',
            PHONE: 'Phone Number',
            EMAIL: 'Email',
            CONTACT_TITLE: 'Contact Title',
            USER_LINKED_IN: 'LinkedIn Link',
            USER_OBSERVATION: 'User Observation',
            USER_DATE_OF_BIRTH: 'Birthdate',
            REFERRAL_SOURCE: 'Referral Source',
            STATUS: 'Status',
            CONTACT_OBSERVATION: 'Contact Observation',
            RATING: 'Rating',
            PROJECT_DESCRIPTION: 'Project Description',
            BUDGET: 'Budget',
            ATTACHMENTS: 'Attachments',
            ADDRESS_STREET_NAME: 'Street Name',
            BACKGROUND_INFO: 'Background Information',
            ACTIONS: 'Actions',
            CHANGE_STATUS: 'Change status',
            STATUS_ACTIVE: 'active',
            STATUS_INACTIVE: 'inactive',
            COMPANY_NAME: 'Company Name',
            VAT_NUMBER: 'VAT Number',
            INDUSTRY: 'Industry',
            CATEGORIES: 'Categories',
            ADDRESS_NAME: 'Address',
            ADDRESS_CITY: 'City',
            ADDRESS_STATE: 'State',
            ADDRESS_COUNTRY: 'Country',
            WEBSITE: 'Website',
            ZIP_CODE: 'Zip code',
            INFORMATION: 'Information',
            SECTION: 'Section',
            COMPANY_FACEBOOK_PROFILE: 'Facebook Profile',
            COMPANY_LINKEDIN_PROFILE: 'LinkedIn Profile',
            COMPANY_REVENUE: 'Revenue',
            COMPANY_FOUNDATION_DATE: 'Foundation Date',
            COMPANY_EMAIL_ADDRESS: 'Email',
            COMPANY_DETAILS: 'Company Details',
            CREATE_SUCCESS: 'New contact added!',
            CREATE_ERROR: 'An error occurred!',
            NOTE: 'Note',
            NOTE_SUCCESS: 'New note added!',
            NOTE_DELETE_SUCCESS: 'Note deleted',
            NOTE_EDIT_SUCCESS: 'Note modified',
            NEWSLETTER_SUBSCRIBED: 'Subscribed',
            NEWSLETTER_SUBSCRIBE: 'Subscribe',
            NEWSLETTER: 'Newsletter',
            SELECT_COMPANY: 'Select Company',
            ADD_COMPANY: 'Add Company',
            SELECT: {
                COUNTRY: 'Select Country',
                STATE: 'Select State',
                CITY: 'Select City',
                TITLE: 'Select Title',
                SOURCE: 'Select Source',
                CATEGORIES: 'Select Categories',
                INDUSTRY: 'Select Industry',
                TYPE: 'Select Type'
            },
        },
        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.'
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.'
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username'
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
            }
        },
        ECOMMERCE: {
            COMMON: {
                ALL: 'All',
                SUSPENDED: 'Suspended',
                ACTIVE: 'Active',
                FILTER: 'Filter',
                BY_STATUS: 'by Status',
                BY_TYPE: 'by Type',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individual',
                SEARCH: 'Search',
                IN_ALL_FIELDS: 'in all fields'
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Customers',
                CUSTOMERS_LIST: 'Customers list',
                NEW_CUSTOMER: 'New Customer',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Customer Delete',
                    DESCRIPTION: 'Are you sure to permanently delete this customer?',
                    WAIT_DESCRIPTION: 'Customer is deleting...',
                    MESSAGE: 'Customer has been deleted'
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Customers Delete',
                    DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                    WAIT_DESCRIPTION: 'Customers are deleting...',
                    MESSAGE: 'Selected customers have been deleted'
                },
                UPDATE_STATUS: {
                    TITLE: 'Status has been updated for selected customers',
                    MESSAGE: 'Selected customers status have successfully been updated'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Customer has been updated',
                    ADD_MESSAGE: 'Customer has been created'
                }
            }
        },
        PRODUCTS: {
            LIST: 'Products & Services',
            NEW: 'New Listing',
            EDIT: 'Edit product',
            PROD_TITLE: 'Listing Name',
            PROD_DESCRIPTION: 'Description',
            PROD_TAGS: 'Tags',
            PROD_SUBCAT: 'Subcategories',
            PROD_CAT: 'Category',
            PROD_PRICE: 'Price',
            PROD_ORDER: 'Quantity',
            PROD_ESTIM_TIME: 'Estimated time',
            TYPE_SERVICE_PRICE: 'Hour',
            PROD_SERVICE: 'Service',
            PROD_PRODUCT: 'Product',
            PROD_TYPE: 'Type',
            PROD_STATUS: 'Status',
            SERVICE_TIME: 'Hours',
            PRODUCT_QUANT: 'Products',
            CHOOSE_TYPE: 'Choose Type'
        },
        HOLIDAYS: {
            ADD: 'Create Request',
            DAYS_REMAINING: 'Leave Days Remaining',
            STATUS: 'Status',
            APPROVED: 'Approved',
            REJECTED: 'Rejected',
            CANCELLED: 'Cancelled',
            NEW: 'New',
            VIEW_CALENDAR: 'View Calendar',
            CALENDAR: 'Calendar'
        },
        GENERAL: {
            EDIT: 'Edit',
            ADD: 'Add',
            NEW: 'New',
            BACK: 'Back',
            CANCEL: 'Cancel',
            SAVE: 'Save',
            RESET: 'Reset',
            UPLOAD: 'Upload',
            IMPORT: 'Import',
            CLEAR: 'Clear',
            REQUIRED_FIELD: 'This field is required!',
            SELECTED_RECORDS_COUNT: 'Selected records count: ',
            DELETE_ALL: 'Delete all',
            UPDATE_STATUS: 'Update status',
            SHOWING_ROWS_FROM: 'Showing rows from',
            SHOWING_ROWS_TO: 'to',
            SHOWING_ROWS_OF: 'of',
            STATUS_UPDATE_SELECTED_ITEMS: 'Status update for selected items',
            ACTIONS: 'Actions',
            STATUS_ACTIVE: 'Active',
            STATUS_NEW: 'New',
            STATUS_IN_PROGRESS: 'In Progress',
            STATUS_COMPLETED: 'Completed',
            STATUS_CANCELLED: 'Cancelled',
            STATUS_INACTIVE: 'Archived',
            STATUS_DRAFT: 'Draft',
            STATUS_PUBLISHED: 'published',
            CHANGE_STATUS: 'Change status',
            CHOOSE_STATUS: 'Choose status',
            CHOOSE_OPTION: 'Choose an option',
            STATUS_CHANGED: 'Status changed successfully!',
            ERROR: 'Something did not work. Please try again.',
            YES: 'Yes',
            NO: 'No',
            ACTIVE: 'Active',
            INACTIVE: 'Inactive',
            STATUS: 'Status',
            LIST: 'Offers list',
            SAVE_AS_DRAFT: 'Save as Draft',
            DRAFT: 'Draft',
            NO_FILES_SELECTED: 'No files selected...',
            NO_FILES_UPLOADED: 'No files uploaded...',
            CLICK_TO_IMPORT_FILE_HERE: 'Click here to import file',
            TAKE_NOTICE:'Take notice',
            AWARE: 'I am aware!',
            POSTED_AT: 'Posted at'
        },
        VALIDATION: {
            REQUIRED_FIELD: 'This field is required!',
            INVALID: '{{name}} is not valid',
            REQUIRED: '{{name}} is required',
            MIN_LENGTH: '{{name}} minimum length is {{min}}',
            MIN_HOURS: 'This field must be greater than or equal to 0.',
            AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
            NOT_FOUND: 'The requested {{name}} is not found',
            INVALID_LOGIN: 'The login detail is incorrect',
            MIN_LENGTH_FIELD: 'Minimum field length is {{minlength}}',
            MAX_LENGTH_FIELD: 'Maximum field length is {{maxlength}}',
            INVALID_FIELD: 'Field is not valid',
            INVALID_PATTERN_TITLE: 'Field must start with an Uppercase letter, contain only alphanumeric characters, spaces and dashes only between words.',
        },
        TABLES: {
            processing: 'Processing...',
            lengthMenu: 'Show _MENU_ entries',
            zeroRecords: 'No matching records found',
            info: 'Showing _START_ to _END_ of _TOTAL_ entries',
            infoEmpty: 'Showing 0 to 0 of 0 entries',
            infoFiltered: '(filtered from _MAX_ total entries)',
            search: 'Search:',
            paginate: {
                first: 'First',
                last: 'Last',
                next: 'Next',
                previous: 'Previous'
            },
            aria: {
                sortAscending: ': activate to sort column ascending',
                sortDescending: ': activate to sort column descending'
            },
            loadingRecords: 'Loading...',
        },
        MENU: {
            PRODUCTS: 'Products',
            ADD_PRODUCT: 'Add products',
            LIST_PRODUCTS: 'List products',
            CONTACTS: 'Contacts',
            ADD_CONTACT: 'Add contact',
            LIST_CONTACTS: 'List contacts',
            OFFERS: 'Offers',
            ADD_OFFER: 'Add offer',
            LIST_OFFERS: 'List offers',
            CONTRACTS: 'Contracts',
            ADD_CONTRACT: 'Add contract',
            LIST_CONTRACTS: 'List contracts',
            PROJECTS: 'Projects',
            ADD_PROJECT: 'Add project',
            LIST_PROJECTS: 'List projects',
            TASKS: 'Tasks',
            TASK: 'Task',
            ADD_TASK: 'Add task',
            NEW_TASK: 'New Task',
            LIST_TASKS: 'List tasks',
            DIRECTIVES: 'Directives',
            ADD_DIRECTIVE: 'Add directive',
            LIST_DIRECTIVES: 'List directives',
            PROCEDURES: 'Procedures',
            ADD_PROCEDURE: 'Add procedure',
            LIST_PROCEDURES: 'List procedures',
            FEEDBACK: 'Feedback',
            ADD_FEEDBACK: 'Add feedback',
            LIST_FEEDBACK: 'List feedback',
            USERS: 'My Users',
            ADD_USER: 'Add User',
            LIST_USERS: 'List users',
            REPORTS: 'Reports',
            ADD_REPORT: 'Add report',
            LIST_REPORTS: 'List reports',
            MY_PROFILE: 'My Profile',
            EDIT_MY_PROFILE: 'Edit profile',
            MY_SCHEDULE: 'My Schedule',
            MY_HOLIDAY_REQUEST: 'My Holiday Requests',
            DASHBOARD: 'Dashboard',
            ADMIN: 'Admin',
            TICKETS: 'Tickets'
        },
        USER: {
            CHANGE_PASS: 'Change Password',
            AVATAR: 'Avatar',
            LEAVE_DAYS: 'Remaining Leave Days',
            CONTRACT_LEAVE_DAYS: 'Contract Leave Days',
            WORK_INFO: 'Work Information',
            PERSONAL_INFO: 'Personal information',
            UPDATE_PERS_INFO: 'Update your personal informaiton',
            F_NAME: 'First Name',
            L_NAME: 'Last Name',
            ADDRESS: 'Address',
            CITY: 'City',
            COUNTRY: 'Country',
            PERSONAL_PHONE: 'Personal phone',
            DEPARTMENT: 'Department',
            ROLES: 'Roles',
            TITLE: 'Title',
            HIRING_DATE: 'Hiring Date',
            EMP_STATUS: 'Employee Status',
            EMP_TYPE: 'Employee Type',
            WORK_PHONE: 'Work phone',
            SOURCE_OF_HIRE: 'Source of hire',
            REPORTING_TO: 'Reporting to',
            COMPANY_NAME: 'Company Name',
            TEAM_NAME: 'Team Name',
            TEAM: 'Team',
            BLOOD_GROUP: 'Blood Group',
            PERSONAL_EMAIL: 'Personal Email',
            BIRTH_DATE: 'Date of birth',
            GENDER: 'Gender',
            NATIONALITY: 'Nationality',
            CIVIL_STATUS: 'Civil Status',
            DRIVER_LICENSE: 'Driver license',
            HOBBY: 'Hobbies',
            BACKUP_CONTACT: 'Backup contact',
            BACKUP_C_NAME: 'Full name',
            BACKUP_C_PHONE: 'Phone number',
            BACKUP_C_RELATIONSHIP: 'Backup contact relationship',
            BACKUP_C_EMAIL: 'Email Address',
            LINKEDIN_PROFILE: 'Linkedin profile',
            FACEBOOK_PROFILE: 'Facebook profile',
            OTHER_SOCIAL_ACCOUNT: 'Other social media account',
            WORK_EXP_PREV_COMPANY: 'Company',
            WORK_EXP_JOB_TITLE: 'Job title',
            WORK_EXP_START_DATE: 'Start date',
            WORK_EXP_END_DATE: 'End date',
            WORK_EXP_JOB_DESC: 'Job description',
            EDUCATION_SCHOOL: 'School name',
            EDUCATION_DEGREE: 'Degree',
            EDUCATION_FIELD: 'Field of study',
            EDUCATION_YEAR: 'Year of completion',
            ADD_EDUCATION: 'Add education info',
            ADD_WORK: 'Add work experience',
            DELETE_INFO: 'Remove Info',
            DELETE_WORK_EXP: 'Remove Experience',
            REMOVE: 'Remove',
            ADD: 'Add',
            DELETE: 'Delete',
            BASIC_INFO: 'Basic info',
            JOB_INFO: 'Job Info',
            WORK_EXP: 'Work experience',
            EDUCATION: 'Education',
            FULL_NAME: 'Full name',
            COMPANY: 'Company',
            STATUS: 'Status',
            NEW: 'Add User',
            LIST: 'Users List',
            LIST_ALL: 'All Users',
            LIST_TEAM: 'My Team List'
        },
        CLIENT: {
            ADD: 'Add Client',
            LIST: 'My Clients List',
            LIST_ALL: 'All Clients List',
            FULL_NAME: 'Full Name',
            EMAIL: 'Email',
            PERSONAL_PHONE: 'Personal Phone',
            COMPANY_NAME: 'Company Name',
            DEPARTMENT: 'Department'
        },
        LISTS: {
            WORK: {
                DEPARTMENT_1: 'Department 1 EN',
                DEPARTMENT_2: 'Department 2 EN',
                DEPARTMENT_3: 'Department 3 EN'
            },
            ROLE: {
                ROLE_1: 'Role 1',
                ROLE_2: 'Role 2',
                ROLE_3: 'Role 3'
            },
            TITLE: {
                TITLE_1: 'Title 1',
                TITLE_2: 'Title 2',
                TITLE_3: 'Title 3'
            },
            EMPLOYEE_STATUS: {
                STATUS_1: 'Status 1',
                STATUS_2: 'Status 2',
                STATUS_3: 'Status 3'
            },
            EMPLOYEE_TYPE: {
                TYPE_1: 'Type 1',
                TYPE_2: 'Type 2',
                TYPE_3: 'Type 3'
            },
            HIRE_SOURCE: {
                SOURCE_1: 'Source 1',
                SOURCE_2: 'Source 2',
                SOURCE_3: 'Source 3'
            },
            GENDER: {
                MALE: 'Male',
                FEMALE: 'Female',
            }
        },
        PROCEDURES: {
            TITLE: 'Title',
            VIEW_PROCEDURE:'View Procedure',
            CATEGORIES: 'Categories',
            DESCRIPTION: 'Description',
            STATUS: 'Status',
            DATE: 'Data',
            ACTIONS: 'Actions',
            LIST: 'Procedures list',
            ADD_NEW: 'New procedure'
        },
        DIRECTIVES: {
            TITLE: 'Title',
            VIEW_DIRECTIVE:'View Directive',
            CATEGORIES: 'Categories',
            DESCRIPTION: 'Description',
            STATUS: 'Status',
            DATE: 'Data',
            ACTIONS: 'Actions',
            LIST: 'Procedures list',
            ADD_NEW: 'New directive'
        },
        NOTES: {
            SEND_NOTE: 'Send',
            NOTES_LABEL: 'Notes'
        },
        TICKETS: {
            ADD_TICKET: 'Add Ticket',
            ARCHIVE_TICKET: 'Archive Ticket',
            CLOSE_TICKET: 'Close Ticket',
            CANCEL_TICKET: 'Cancel',
            SUBMIT_TICKET: 'Submit',
            SAVE_TICKET: 'Save',
            EDIT_TICKET: 'Edit',
            READ_TICKET: 'Review Issue'
        },
        LEAVE_REQUEST: {
            TYPE: 'Type',
            REASON: 'Reason',
            RANGE_DATE: 'Start Date - End Date',
            ATTACHMENT: 'Attachment'
        },
        WORK_TRACKER: {
            START: 'START Work Confirmation',
            END: 'END Work Confirmation',
            START_CONFIRM: 'Are you sure you want to START the program? This action cannot be undone.',
            END_CONFIRM: 'Are you sure you want to END the program? This action cannot be undone.'
        }
    }
};
