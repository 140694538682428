<button type="button" class="btn-default btn-green-empty flex-row-reverse" (click)="getCsvFile()">
    Export CSV
</button>


<ng-template #exportExcel>
    <div class="modal-header">
        <h4 class="modal-title text-white">Export to Excel</h4>
    </div>
    <div class="modal-body">
        <div class="input-group">
            <input class="form-control form-control-lg form-layout"
                   placeholder="Select range date"
                   angular-mydatepicker
                   name="myDateRange"
                   [formControl]="myDateRange"
                   [options]="myOptions"
                   #dp="angular-mydatepicker"/>

            <div class="input-group-append">
                <button type="button" class="calendar-btn btn-red"
                        (click)="dp.clearDate()">
                    <span class="fa fa-times"></span>
                </button>
            </div>

            <div class="input-group-append">
                <button type="button" class="calendar-btn btn-main"
                        (click)="dp.toggleCalendar()">
                    <span class="fa fa-calendar"></span>
                </button>
            </div>
        </div>
        <div class="error-warning" translate="VALIDATION.REQUIRED_FIELD"
             *ngIf="myDateRange.invalid && myDateRange.touched"></div>

    </div>
    <div class="modal-footer">
        <button class="btn-default btn-main" type="button" (click)="getCsvFile()">Export</button>
    </div>
</ng-template>
