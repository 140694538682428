<div id="files-upload">
    <!--Create Layout-->
    <div class="row create-view" *ngIf="!urlId">
        <!--Form Intro-->
        <div class="col-12 mb-4">
            <span class="files-header" translate="Attachments"></span>
        </div>

        <!--Files-->
        <div class="col-12 mb-6">
            <!--No File-->
            <div class="no-files" *ngIf="selectedFiles.length === 0 || selectedFiles === null">
                <i class="fa fa-photo-video mb-2"></i>
                <span>No files uploaded...</span>
            </div>

            <!--Selected Files-->
            <div class="files-list" *ngIf="selectedFiles.length > 0">
                <ul class="list-unstyled blue-scroll-bar m-0">
                    <li *ngFor="let file of selectedFiles" class="mb-3">
                        <div class="file-wrapper">
                            <span class="file-name">
                                <i class="fa mr-3" [ngClass]="{
                               'fa-file-image': (file.name.substring(file.name.indexOf('.')) === '.jpg' || file.name.substring(file.name.indexOf('.')) === '.png' || file.name.substring(file.name.indexOf('.')) === '.PNG' || file.name.substring(file.name.indexOf('.')) === '.jpeg'),
                               'fa-file-archive': (file.name.substring(file.name.indexOf('.')) === '.zip'),
                               'fa-file-video': (file.name.substring(file.name.indexOf('.')) === '.mp4' || file.name.substring(file.name.indexOf('.')) === '.avi'),
                               'fa-file': (file.name.substring(file.name.indexOf('.')) === '.txt' || file.name.substring(file.name.indexOf('.')) === '.doc' || file.name.substring(file.name.indexOf('.')) === '.pdf' || file.name.substring(file.name.indexOf('.')) === '.rtf' || file.name.substring(file.name.indexOf('.')) === '.docx') }">
                                </i>
                                <span>{{file.name.length < 25 ? file.name : (file.name | slice : 0 : 25) + (file.name.substring(file.name.indexOf('.')))}}</span>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!--Form Action-->
        <div class="col-12">
            <div class="upload-action">
                <label for="file-upload-create" class="mb-0">
                    <!--Add Files-->
                    <span translate="GENERAL.IMPORT" *ngIf="selectedFiles.length === 0"
                          class="btn-default btn-main-empty flex-row-reverse cursor-pointer">
                        <span class="fa fa-file-import mr-3"></span>
                    </span>

                    <!--Disabled Button-->
                    <button translate="GENERAL.IMPORT" type="button" *ngIf="selectedFiles.length > 0"
                            [disabled]="selectedFiles.length > 0"
                            class="btn-default btn-main flex-row-reverse cursor-pointer clear-inactive">
                        <span class="fa fa-file-import mr-3"></span>
                    </button>

                    <!--Hide Initial Input-->
                    <input type="file" name="file" id="file-upload-create" class="d-none" multiple
                           accept=".png, .jpg, .jpeg, .rtf, .pdf, .txt, .docx, .zip"
                           (change)="onFileChange($event)"/>
                </label>

                <!--Clear Button-->
                <button type="button" class="btn-default btn-red"
                        [ngClass]="{'clear-inactive' : selectedFiles.length === 0}"
                        [disabled]="selectedFiles.length === 0"
                        (click)="clearFiles()">
                    <span class="fa fa-trash"></span>
                </button>
            </div>
        </div>
    </div>


    <!--Edit Layout-->
    <div class="row edit-view" *ngIf="urlId">
        <!--Form Intro-->
        <div class="col-12 mb-4">
            <div class="d-flex justify-content-between align-items-center">
                <span class="files-header" translate="Attachments"></span>
                <span class="file-feedback file-success" *ngIf="fileSuccess">{{feedbackMessage}}</span>
                <span class="file-feedback file-error" *ngIf="fileError">{{feedbackMessage}}</span>
            </div>
        </div>

        <!--Files List TAB-->
        <div class="col-12 mb-6" id="view-attachments" [ngbCollapse]="isCollapsed">
            <!--No File-->
            <div class="no-files mb-6" *ngIf="moduleFiles === null || moduleFiles.length === 0">
                <i class="fa fa-photo-video mb-2"></i>
                <span>No files uploaded...</span>
            </div>

            <!--Uploaded Files-->
            <div class="files-list mb-6" *ngIf="moduleFiles !== null && moduleFiles.length > 0">
                <ul class="list-unstyled blue-scroll-bar m-0">
                    <li *ngFor="let file of moduleFiles; let index = index" class="mb-3">
                        <div class="file-wrapper">
                            <a class="file-name" href="{{file.link}}" target="_blank">
                                <i class="fa mr-3" [ngClass]="{
                               'fa-file-image': (file.name.substring(file.name.indexOf('.')) === '.jpg' || file.name.substring(file.name.indexOf('.')) === '.png' || file.name.substring(file.name.indexOf('.')) === '.PNG' || file.name.substring(file.name.indexOf('.')) === '.jpeg'),
                               'fa-file-archive': (file.name.substring(file.name.indexOf('.')) === '.zip'),
                               'fa-file-video': (file.name.substring(file.name.indexOf('.')) === '.mp4' || file.name.substring(file.name.indexOf('.')) === '.avi'),
                               'fa-file': (file.name.substring(file.name.indexOf('.')) === '.txt' || file.name.substring(file.name.indexOf('.')) === '.doc' || file.name.substring(file.name.indexOf('.')) === '.pdf' || file.name.substring(file.name.indexOf('.')) === '.rtf' || file.name.substring(file.name.indexOf('.')) === '.docx') }">
                                </i>
                                <span>{{file.name.length < 25 ? file.name : (file.name | slice : 0 : 25) + (file.name.substring(file.name.indexOf('.')))}}</span>
                            </a>

                            <span class="fa fa-times" (click)="removeElement(index)"></span>
                        </div>
                    </li>
                </ul>
            </div>

            <!--Form Action-->
            <div class="upload-action"
                 *ngIf="userRollsList | roleChecker : ['ROLE_super_admin', 'ROLE_admin','ROLE_client','ROLE_TicketsFull', 'ROLE_ContactsFull', 'ROLE_ProjectsFull', 'ROLE_DirectivesFull', 'ROLE_ProceduresFull']">
                <!--Add Files-->
                <label for="file-upload-edit" class="mb-0" *ngIf="!filesChanges">
                    <span translate="GENERAL.IMPORT" class="btn-default btn-main-empty flex-row-reverse cursor-pointer"
                          (click)="selectedTab('import-files')"
                          [attr.aria-expanded]="!isCollapsed"
                          aria-controls="view-attachments">
                        <span class="fa fa-file-import mr-3"></span>
                </span>

                    <!--Hide Initial Input-->
                    <input type="file" name="file" id="file-upload-edit" class="d-none" multiple
                           accept=".png, .jpg, .jpeg, .rtf, .pdf, .txt, .docx, .zip"
                           (change)="onFileChange($event)"/>
                </label>

                <!--Save Changes-->
                <button translate="GENERAL.SAVE" type="button" *ngIf="filesChanges" (click)="saveFilesChanges()"
                        class="btn-default btn-green-empty flex-row-reverse cursor-pointer">
                    <span class="fa fa-file-upload mr-3"></span>
                </button>

                <!--Cancel Changes-->
                <button translate="GENERAL.CANCEL" type="button" *ngIf="filesChanges" (click)="cancelFilesChanges()"
                        class="btn-default btn-red-empty flex-row-reverse cursor-pointer">
                </button>
            </div>

        </div>

        <!--Add New Files TAB-->
        <div class="col-12 mb-6" id="import-files" [ngbCollapse]="isCollapsed2">

            <!--No File-->
            <div class="no-files mb-6" *ngIf="selectedFiles.length === 0">
                <i class="fa fa-photo-video mb-2"></i>
                <span>No files selected...</span>
            </div>

            <!--Selected Files-->
            <div class="files-list mb-6" *ngIf="selectedFiles.length > 0">
                <ul class="list-unstyled blue-scroll-bar m-0">
                    <li *ngFor="let file of selectedFiles" class="mb-3">
                        <div class="file-wrapper">
                             <span class="file-name">
                                 <i class="fa mr-3" [ngClass]="{
                               'fa-file-image': (file.name.substring(file.name.indexOf('.')) === '.jpg' || file.name.substring(file.name.indexOf('.')) === '.png' || file.name.substring(file.name.indexOf('.')) === '.PNG' || file.name.substring(file.name.indexOf('.')) === '.jpeg'),
                               'fa-file-archive': (file.name.substring(file.name.indexOf('.')) === '.zip'),
                               'fa-file-video': (file.name.substring(file.name.indexOf('.')) === '.mp4' || file.name.substring(file.name.indexOf('.')) === '.avi'),
                               'fa-file': (file.name.substring(file.name.indexOf('.')) === '.txt' || file.name.substring(file.name.indexOf('.')) === '.doc' || file.name.substring(file.name.indexOf('.')) === '.pdf' || file.name.substring(file.name.indexOf('.')) === '.rtf' || file.name.substring(file.name.indexOf('.')) === '.docx') }">
                                    </i>
                                <span>{{file.name.length < 25 ? file.name : (file.name | slice : 0 : 25) + (file.name.substring(file.name.indexOf('.')))}}</span>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>

            <!--Form Action-->
            <div class="upload-action">

                <!--Add Files-->
                <label for="file-upload-create2" class="mb-0" *ngIf="selectedFiles.length === 0">
                    <span translate="GENERAL.IMPORT" class="btn-default btn-main-empty flex-row-reverse cursor-pointer">
                        <span class="fa fa-file-import mr-3"></span>
                    </span>

                    <!--Hide Initial Input-->
                    <input type="file" name="file" id="file-upload-create2" class="d-none" multiple
                           accept=".png, .jpg, .jpeg, .rtf, .pdf, .txt, .docx, .zip"
                           (change)="onFileChange($event)"/>
                </label>

                <!--Upload File-->
                <button translate="GENERAL.UPLOAD" type="button" *ngIf="selectedFiles.length > 0"
                        class="btn-default btn-green-empty flex-row-reverse cursor-pointer"
                        (click)="uploadFile()"
                        [attr.aria-expanded]="!isCollapsed2">
                    <span class="fa fa-file-upload mr-3"></span>
                </button>


                <!--Clear Button-->
                <button type="button" class="btn-default btn-red-empty"
                        (click)="clearFiles(); selectedTab('view-attachments')"
                        [attr.aria-expanded]="!isCollapsed2"
                        aria-controls="view-attachments" translate="GENERAL.CANCEL">
                </button>
            </div>
        </div>
    </div>

</div>
