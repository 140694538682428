import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WorkTrackerModule} from '../work-tracker.module';

@Injectable({
    providedIn: 'root'
})
export class WorkTrackerService {


    constructor(private http: HttpClient) {
    }

    createWorkTracker() {
        return this.http.post<WorkTrackerModule>('/api/createWorkTracker', {});
    }

    addWorkTrackerForUser(startDate, userId, data) {
        return this.http.post('/api/updateWorkTrackerPerDay?date=' + startDate + '&userId=' + userId, data);
    }

    updatedEntryTracker(id: string, data) {
        return this.http.put('/api/updateTracker?workTrackerId=' + id, data);
    }

    endWorkTracker() {
        return this.http.put<WorkTrackerModule>('/api/endWorkTracker', {});
    }

    getWorkTrackerById(workTrackerId: string) {
        return this.http.get<WorkTrackerModule>('/api/getWorkTrackerById?workTrackerId=' + workTrackerId);
    }

    getAllWorkTrackers() {
        return this.http.get<WorkTrackerModule>('/api/getAllWorkTrackers');
    }

    getCurrentUserWorkTrackers() {
        return this.http.get<WorkTrackerModule>('/api/getCurrentUserWorkTrackers');
    }

    validateWorkingDay(entryInfo) {
        return this.http.put('/api/validateWorkingDay', entryInfo);
    }

    getWorkTrackersDailyCurrentUser(startDate, endDate) {
        return this.http.get('/api/getWorkTrackersDailyCurrentUser?startDate=' + startDate + '&endDate=' + endDate);
    }

    getWorkTrackersForCurrentWeek() {
        return this.http.get('/api/getWorkTrackersForCurrentWeek');
    }

    getWorkTrackersDailyUser(workObject: object) {
        return this.http.post('/api/getWorkTrackersDailyUser', workObject);
    }

    getSpecificDateWorkTrackersForUser(date, userId) {
        return this.http.get('/api/getSpecificDateWorkTrackersForUser?date=' + date + '&userId=' + userId);
    }

    sendLeaveRequestChangeStatusEmail(id) {
        return this.http.get('/api/sendLeaveRequestChangeStatusEmail?leaveRequestId=' + id);
    }

    // EXPORT To CSV
    exportWorkTrackersUser(start, end, userId) {
        return this.http.get('/api/exportUserWorkTrackerXLS?startDate=' + start + '&endDate=' + end + '&targetUserId=' + userId, {responseType: 'blob'});
    }

    exportUserLeaveRequests(start, end, userId) {
        return this.http.get('/api/exportUserLeaveRequestsXLS?startDate=' + start + '&endDate=' + end + '&targetUserId=' + userId, {responseType: 'blob'});
    }

    exportAllUsersLeaveRequests(start, end) {
        return this.http.get('/api/exportLeaveRequestsIntervalXLS?startDate=' + start + '&endDate=' + end, {responseType: 'blob'});
    }

}
